import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CleanLayout from "layouts/Clean";
import { SEO, Intro, Title, Subtitle, Label, Text } from "components";

const pageProperties = {
  title: "Sognatori e ambiziosi cercasi",
  description:
    "Qui non conta il curriculum, ma la tua voglia di metterti in gioco e fare la differenza. Stiamo costruendo qualcosa di unico. Non cerchiamo collaboratori o dipendenti, ma ragazzi che vogliano costruirlo insieme a noi.",
};

const WorkWithUs = () => {
  const {
    allContentfulJob: { nodes: jobs },
  } = useStaticQuery(GET_JOBS);
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Lavora in una web agency"
        description="Se condividi i nostri ideali e hai voglia di metterti in gioco, in Trame Digitali c'è un posto per te."
      />
      <div className="w-screen px-8 md:px-28">
        <Intro {...pageProperties} />

        <section className="mb-16">
          <div className="flex flex-col">
            {jobs.map((job, index) => {
              const {
                area,
                description,
                experience,
                extraSkills,
                hardSkills,
                name,
                location,
                slug,
                softSkills,
                subtitle,
                title,
              } = job;
              return (
                <div
                  key="index"
                  className="flex flex-col shadow-button border-4 border-black rounded-md p-6"
                >
                  <div className="mb-8">
                    <Label name={area} />
                  </div>
                  <Title size="small">{title}</Title>
                  <Subtitle>{subtitle}</Subtitle>
                  {/* <Text>{description?.description}</Text> */}
                  <div className=""></div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </CleanLayout>
  );
};

const GET_JOBS = graphql`
  query getJobs {
    allContentfulJob(filter: { node_locale: { eq: "it" } }) {
      nodes {
        area
        description {
          description
        }
        experience
        extraSkills {
          extraSkills
        }
        hardSkills {
          hardSkills
        }
        name
        location
        slug
        softSkills {
          softSkills
        }
        subtitle
        title
        childContentfulJobDescriptionTextNode {
          description
        }
      }
    }
  }
`;

export default WorkWithUs;
